import React from "react"
import ProductPage from "../../layouts/product-page"

const model = {
  eng: {
    id: "hub",
    model: "Data Center 42",
    os: "Windows 10, 8/7, vista, XP; Mac OS X(10.6.X-10.10.X-)",
    chipset: "Qualcomm MDM9207+MT628NM",
    band:
      "GSM 850/900/1800/1900, WCDMA B1/2/4/5/8, LTE FDD B2/3/4/5/7/8/12/13/17/28/66",
    display: "N/A",
    speed: "FDD-LTE DL 150Mbps/UL 50Mbps",
    memory: "2GB ROM+2GB RAM",
    dimensions: "152 x 130 x 32 mm",
    cameras: "N/A",
    video: "N/A",
    audio: "N/A",
    connectivity: "WiFi 802.11 b/g/n; 2.4GHz; 20 MHz; 2x2 MIMo; Up to 32 users",
    sensor: "G-sensor, Proximity Sensor, Light Sensor",
    interface:
      "1xDC power connector (12V/1A); 2x WAN/LAN (RJ45), 100Mbit/s, 1x RJ11 Port, 2x SMA External antenna connectors; 1x Reset Hole, 1x3FF SIM card slot,",
    battery: "N/A",
    cmf: "N/A",
    certifications: "CE, FCC, GCF, RoHS, WEEE,REACH",
    accessories: "2xSMA External Antenna (Optional)",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3dP7VIA_QvuZaUwGsQ-zr-SuMHScfuQc80jwVGLGul9uOmx4GnRb6OKvWUmpWfGnA5Z1qVnnZay4rGixAJbASDnW8mf59YQ_gK7AqZx6_QayySW-SjAGgHPH1yLOZKl1LA-wxfD5PNFWREkS7HVl8Y=d",
  },
  esp: {
    id: "hub",
    model: "Data Center 42",
    os: "Windows 10, 8/7, vista, XP; Mac OS X(10.6.X-10.10.X-)",
    chipset: "Qualcomm MDM9207+MT628NM",
    band:
      "GSM 850/900/1800/1900, WCDMA B1/2/4/5/8, LTE FDD B2/3/4/5/7/8/12/13/17/28/66",
    display: "N/A",
    speed: "FDD-LTE DL 150Mbps/UL 50Mbps",
    memory: "2GB ROM+2GB RAM",
    dimensions: "152 x 130 x 32 mm",
    cameras: "N/A",
    video: "N/A",
    audio: "N/A",
    connectivity: "WiFi 802.11 b/g/n; 2.4GHz; 20 MHz; 2x2 MIMo; Up to 32 users",
    sensor: "G-sensor, Proximity Sensor, Light Sensor",
    interface:
      "1xDC power connector (12V/1A); 2x WAN/LAN (RJ45), 100Mbit/s, 1x RJ11 Port, 2x SMA External antenna connectors; 1x Reset Hole, 1x3FF SIM card slot,",
    battery: "N/A",
    cmf: "N/A",
    certifications: "CE, FCC, GCF, RoHS, WEEE,REACH",
    accessories: "2xSMA External Antenna (Optional)",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3dP7VIA_QvuZaUwGsQ-zr-SuMHScfuQc80jwVGLGul9uOmx4GnRb6OKvWUmpWfGnA5Z1qVnnZay4rGixAJbASDnW8mf59YQ_gK7AqZx6_QayySW-SjAGgHPH1yLOZKl1LA-wxfD5PNFWREkS7HVl8Y=d",
  },
}
export default () => <ProductPage model={model} />
